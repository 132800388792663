import { Outlet, Route, Routes, useLocation } from "react-router-dom";

import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'primeicons/primeicons.css';
import "./App.css";
import "./grid.css";
import Loader from "./components/SharedComponents/Loader";
import ProtectedRoute from "./utils/ProtectedRoute";
import FuelSystemLines from "./pages/FuelSystemLines/FuelSystemLines";
import { useErrorHandler } from "./services/errorhandler";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "./services/auth.service.ts";
import { slice, UpdateToast } from "./store/slices/auth.js";
import { Toast } from "primereact/toast";

const MainLayout = lazy(() => import("./pages/MainLayout"));
const LoginPage = lazy(() => import("./pages/Login/Login"));
const Register = lazy(() => import("./pages/Register/Register"));
const FuelSystemMapping = lazy(() => import("./pages/FuelSystemMapping/FuelSystemMapping"))
const ProductCategories = lazy(() => import("./pages/ProductCategories/ProductCategories"))
const Analytics = lazy(() => import("./pages/Analytics/Analytics"))
const Reporting = lazy(() => import("./pages/Reporting/Reporting"))
const UserProfile = lazy(() => import("./pages/UserProfile/UserProfile"))
const Admin = lazy(() => import("./pages/Admin/Admin"))
const Site = lazy(() => import("./pages/site/Site.js"))


const SuspenseLayout = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
};

const SuspenseWrapper = ({ children }) => {
  return (
    <Suspense fallback={<Loader />}>
      {children}
    </Suspense>
  );
};

function App() {
  const toastRef = useRef(null);
  const errorHandler = useErrorHandler();
  const dispatch = useDispatch();
  const location = useLocation();
  const { loggedIn, toast, profile,isRefreshToken } = useSelector((state) => state.auth);
  const [isRefreshTokenexp,setIsRefreshTokenexp]=useState(isRefreshToken)
  const closeToast = (e) => {
    dispatch(UpdateToast({ severity: null, message: null }));
  }
  // useEffect(() => {
  //   if(loggedIn){
  //     AuthService.getUser().pipe(errorHandler("Get User")).subscribe((response) => {
  //       if (response?.data) {
  //         dispatch(
  //           slice.actions.profile({
  //             profile: response.data,
  //           }));
  //       }
  //     })
  //   }
  // }, [loggedIn])
  useEffect(() => {
    toast?.current?.clear();
    toastRef.current?.show({
      severity: toast.severity,
      detail: toast.message,
      life: 2000,
    });

    return () => {
      toast.current?.clear();
    };
  }, [toast]);


  const decodeToken = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Failed to decode token", error);
      return null;
    }
  }
  const checkAndRefreshToken = (validationsData) => {
    const { accessToken, refreshToken, tokenExpiry } = validationsData;
    if (refreshToken) {
      let _body = { "refresh_token": refreshToken };

      try {
        AuthService.getRefreshToken(_body).subscribe(
          (res) => {
            const { data } = res;
            const decodedToken = decodeToken(data.access_token);
            const newExpiry = decodedToken?.exp * 1000;
            localStorage.setItem("token", data.access_token);
            localStorage.setItem("access_token_expiry", newExpiry);
            setIsRefreshTokenexp(true)
            dispatch(
              slice.actions.logIn({
                token: data.access_token,
                refreshToken: null,
                loggedIn: true,
                accessTokenExpiry: newExpiry,
                isRefreshToken:true
              })
            );
          },
          (error) => {
            dispatch(slice.actions.logout());
          }
        );
      } catch (error) {
        dispatch(slice.actions.logout());
      }
    }
  };

  useEffect(() => {
    let timeoutId;
  
    const checkTokenExpiry = () => {
      const currentPath = location.pathname;
      const refreshToken = localStorage.getItem("refresh_token");
      const tokenExpiry = parseInt(localStorage.getItem("access_token_expiry"), 10);
      const accessToken = localStorage.getItem("token");
  
      if (tokenExpiry && Date.now() >= tokenExpiry) {
        if (currentPath !== '/login') {
          checkAndRefreshToken({ accessToken, refreshToken, tokenExpiry });
        }
      } else {
        const timeLeft = tokenExpiry - Date.now();
        if (timeLeft > 0) {
          timeoutId = setTimeout(() => {
            checkTokenExpiry();
          }, Math.max(timeLeft - 5000, 0)); 
        }
      }
      if (!(localStorage.getItem("token")) && currentPath !== '/login') {
        dispatch(slice.actions.logout());
      }
    };
    checkTokenExpiry();
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId); 
      }
    };
  }, [location.pathname,isRefreshTokenexp]); 

  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<SuspenseWrapper><MainLayout /></SuspenseWrapper>} >
            <Route path={"/"} element={<SuspenseLayout />}>
              <Route path={"/"} element={<Admin />} />
              <Route path={"/fuel-system-mapping"} element={<FuelSystemMapping />} />
              <Route path={"/product-categories"} element={<ProductCategories />} />
              <Route path={"/fuel-system-lines"} element={<FuelSystemLines />} />
              <Route path={"/reporting"} element={<Reporting />} />
              <Route path={"/analytics"} element={<Analytics />} />
              <Route path={"/user-profile"} element={<UserProfile />} />
              <Route path={"/admin"} element={<Admin />} />
              <Route path="/site" element={<Site />} />
            </Route>
          </Route>
        </Route>
        <Route path={"/"} element={<SuspenseLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<Register />} />
        </Route>
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
      {toast?.message && (
        <Toast className="p-5 mb-5" onHide={closeToast} ref={toastRef} />
      )}
    </>
  );
}

export default App;
