import { Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { FUEL_MAPPING_URLS, ROLES_URLS, FUEL_SYSTEM_URLS } from "./apiUrls.ts";

export const RolesService = {
  getUsers: (limit: any, offset: any): Observable<any> => {
    return new Observable<any>((observer) => {
      const url = `${ROLES_URLS.GET_USERS}?limit=${limit + 1}&offset=${offset}`
      ApiService.call(url, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  Users: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_MAPPING_URLS.GROUP, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getPermissions: (page: number, pageSize: number): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${ROLES_URLS.GET_PERMISSIONS}?limit=${page}&offset=${pageSize}`;
      ApiService.call(url, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getRolePermissions: (page: number, pageSize: number): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${ROLES_URLS.GET_ROLE_PERMISSIONS}?limit=${page}&offset=${pageSize}`;
      ApiService.call(url, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },

  createPermission: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(ROLES_URLS.UPDATE_PERMISSION, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },

  updatePermission: (id: number, body: any) => {
    return new Observable<any>((observer) => {
      const url = `${ROLES_URLS.UPDATE_PERMISSION}/${id}`
      ApiService.call(url, "PUT", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  createRole: (body: any) => {
    return new Observable<any>((observer) => {
      ApiService.call(ROLES_URLS.CREATE_ROLE, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  updateUser: (body: any, id: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(`${ROLES_URLS.UPDATE_USER}/${id}`, "PUT", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  deleteUser: (id: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(`${ROLES_URLS.UPDATE_USER}/${id}`, "DELETE", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },

  getFuelStystemLinesList: (page: number, pageSize: number, body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${FUEL_MAPPING_URLS.GET_SYSTEM_LINEREADING_LIST}?limit=${page}&offset=${pageSize}`;
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelLineData: (page: number, pageSize: number, body: any) => {
    return new Observable<any>((observer) => {
      let url = `${FUEL_MAPPING_URLS.GROUP}?limit=${page}&offset=${pageSize}`;

      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelLineFilterData: (body: any) => {
    return new Observable<any>((observer) => {
      let url = `${FUEL_MAPPING_URLS.GROUP}`;

      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
 
  getFuelLineReadingsList: (id: any): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${FUEL_MAPPING_URLS.GET_FUEL_SYSTEM_MINIMAL}`;
      ApiService.call(url, "POST", id).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelLineInventoryList: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${FUEL_MAPPING_URLS.GET_INVENTORY_MINIMAL}`;
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelTankList: (id: any): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${FUEL_MAPPING_URLS.GET_TANK_MINIMAL}/${id}`;
      ApiService.call(url, "GET", "").subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelSystemLinesDetailsParentList: (page: number, pageSize: number, body: any, sortType: any, sortField: any): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = ""
      if (sortField) {
        url = `${FUEL_SYSTEM_URLS.GET_PARENT_FUEL_SYSTEM_LINE_DETAILS}?limit=${page}&offset=${pageSize}&sort_by=${sortType}&sort_on=${sortField}`;
      }
      else {
        url = `${FUEL_SYSTEM_URLS.GET_PARENT_FUEL_SYSTEM_LINE_DETAILS}?limit=${page}&offset=${pageSize}&sort_by=${sortType}&sort_on=facility_name`;
      }
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelSystemLinesDetailsChildList: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_SYSTEM_URLS.GET_CHILD_FUEL_SYSTEM_LINE_DETAILS, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelSystemLinesReadingParentList: (page: number, pageSize: number, body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      let url = `${FUEL_SYSTEM_URLS.GET_PARENT_FUEL_SYSTEM_LINE_READING}?limit=${page}&offset=${pageSize}`;
      ApiService.call(url, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  getFuelSystemLinesReadingChildList: (body: any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_SYSTEM_URLS.GET_CHILD_FUEL_SYSTEM_LINE_READING, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
  fuelSystemLineReadingsExport: (body: any) => {
    // it is used to export CSV fuel system line reading
    return new Observable<any>((observer) => {
      ApiService.call(FUEL_SYSTEM_URLS.FUEL_READING_EXPORTCSV, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  },
}