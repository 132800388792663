
export const statusList = [
    { name: 'All', code: '' },
    { name: 'Pass', code: 'NY' },
    { name: 'Fail', code: 'RM' },
    // { name: 'Completed', code: 'LDN' },
]; 




