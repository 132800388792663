const InterNationalDateformat = (dateString) => {
    const date = new Date(dateString).toISOString();
    const datestr = date.split('T')[0]
    return datestr;
  }
  const formatNumber = (value) => {
    let newVal = value;
    if (typeof value !== "number") {
      newVal = Number(value);
    }
    return newVal ? newVal?.toFixed(2) : "0.00";
  };
  function replaceCommasWithSpaces(input) {
    if (typeof input === "string") {
      return input.split(",").join(" ");
    }
    return input;
  }
export const exportCSV = (parseData) => {
    let fileData = [];
    (typeof parseData === "string") ? fileData = JSON.parse(parseData) : fileData = parseData;
    const csvContent = [];
    fileData?.forEach((parentRow) => {
      csvContent.push([
        "Client Name",
        "Site Name",
        "State",
        "Fuel System Name",
        "Period Start",
        "Period End",
        "Last Reading",
        "Next Reading",
        "Periodic Sales",
        "Periodic Deliverables",
        "Over/Under",
        "Leak Check Status",
      ]);
      csvContent.push([
        parentRow?.parent?.client_name,
        parentRow?.parent?.facility_name,
        parentRow?.parent?.state,
        parentRow?.parent?.Fule_System_Name,
        InterNationalDateformat(parentRow?.parent?.Period_Start),
        InterNationalDateformat(parentRow?.parent?.Period_End),
        InterNationalDateformat(parentRow?.parent?.Last_Reading),
        InterNationalDateformat(parentRow?.parent?.Next_Reading),
        formatNumber(parentRow?.parent?.Periodic_Sales),
        formatNumber(parentRow?.parent?.Periodic_Deliveries),
        formatNumber(parentRow?.parent?.Over_Under),
        parentRow?.parent?.result,
      ]);
      csvContent.push([
        "",
        "Tank Number",
        "Product",
        "Date",
        "Beginning Inventory Volume",
        "Actual Ending Inventory Volume",
        "Sales",
        "Deliveries",
        "Book Value",
        "Variance",
        "Cumulative Variance",
        "Daily Variance",
        "Daily Variance Status"
      ]);
      parentRow.parent?.child?.forEach((childRow) => {
        csvContent.push([
          "",
          replaceCommasWithSpaces(childRow.tank_number),
          childRow.product,
          (InterNationalDateformat(childRow.only_date)),
          (childRow.beginning_inventory_volume),
          childRow.actual_ending_inventory_volume,
          formatNumber(childRow.sales),
          formatNumber(childRow.deliveries),
          formatNumber(childRow.book_value),
          formatNumber(childRow.variance),
          formatNumber(childRow.cumulative_variance),
          formatNumber(childRow.daily_variance),
          childRow.daily_variance_status
        ]);
      });

      csvContent.push([]);
    });

    const csvString = csvContent.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "fuel_system_line_details.csv";
    link.click();
  };
