import React, { useEffect } from 'react'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
function FSLRImportError(props) {
    const { errorData, fileName } = props
    const fileNameBodyTemplate = () => {
        return <div>{fileName}</div>
    }
    return (
        <>
            <div className='card'>
                <DataTable
                    value={errorData ?? []}
                    tableStyle={{ minWidth: '50rem' }}
                    dataKey="error"
                    scrollable={true}
                    scrollHeight={"500px"}
                >
                    <Column header="File Name" body={fileNameBodyTemplate}></Column>
                    <Column field="product" header="Product"></Column>
                    <Column field="store_number" header="Store Number"></Column>
                    <Column field="tank_number" header="Tank Number"></Column>
                    <Column field="error" header="Error" style={{textAlign:"justify"}}></Column>
                </DataTable>
            </div>
        </>
    )
}

export default FSLRImportError