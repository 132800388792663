import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from "react";
import { useCommonService } from '../../services/useCommonService.ts';
import { FuelMappingService } from '../../services/fuelSystem.service.ts';
import { useErrorHandler } from '../../services/errorhandler';
import { useDispatch } from 'react-redux';
import { slice } from "../../store/slices/auth.js";
const NoteDialog = (params) => {
    const { editData, onCancel,name } = params;
    const commonService = useCommonService();
    const dispatch = useDispatch();
    const errorHandler = useErrorHandler();
    const [formValues, setFormValues] = useState({
        fuelsystem_name: editData,
        notes: ""
    });

    const getFuelSystemNotes = (editData) => {
        commonService.startLoading()
        FuelMappingService.getFuelSystemNotes(editData).pipe(errorHandler("Get Product Categories")).subscribe((response) => {
            if (response?.data) {
                setFormValues({ notes: response?.data?.notes ?? "" });
                commonService.stopLoading()
            }
        })
    }
    useEffect(() => {
        getFuelSystemNotes(editData)
    }, [])
    const handleInputChange = (name, value) => {
        const newFormDetails = { ...formValues };
        newFormDetails[name] = value;
        setFormValues(newFormDetails);

    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let is_fuel_reading
        if (name==="fuelline-Reading"){
            is_fuel_reading=true
        }
        if(name==="fuelline-details"){
            is_fuel_reading=false
        }
        const payload = {
            fuelsystem_id: editData, notes: formValues?.notes,
            is_fuel_reading:is_fuel_reading
        }
        FuelMappingService.createFuelSystemNotes(payload).pipe(errorHandler("Save Notes")).subscribe((response) => {
            if (response?.data) {
                dispatch(
                    slice.actions.updateToast({
                        severity: "success",
                        message: "Notes successfully created.",
                    })
                );
            }
        })
        onCancel()
    };
    return (
        <form className="fuel-form" onSubmit={handleSubmit}  >
            <div className="m-3 d-flex align-items-center">
                <InputTextarea variant="filled" rows={5} cols={30} name='notes' style={{ width: "300px", height: "150px" }} placeholder='Enter Notes' onChange={(e) => handleInputChange("notes", e.target.value)} value={formValues?.notes} />
            </div>
            <div className="form-buttons">
                <Button type="button" className="p-cancel" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="submit" className="p-save">
                    Save
                </Button>
            </div>
        </form>
    );
};

export default NoteDialog;

