import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ProductService } from "../../services/product.service.ts";
import { useErrorHandler } from "../../services/errorhandler";
import { RolesService } from "../../services/roles.service.ts";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect";
import { format } from "date-fns";

const FuelSystemLinesForm = ({ editData, onCancel, onSave, site, siteName }) => {
  const errorHandler = useErrorHandler();
  const [productList, setProductList] = useState([]);
  const [productValue, setProductValue] = useState({});
  const [fuelsystemValue, setfuelsystemValue] = useState(null)
  const [tankValue, setTankValue] = useState([])
  const [salesValues, setSalesValues] = useState(0)
  const [deliveriesValue, setDeliveries] = useState(0)
  const [actualendingValue, setactualendingValue] = useState(0)
  const [varianceValue, setvarianceValue] = useState("")
  const [bookvalue, setbookvalue] = useState("")
  const [fuelSystemList, setFuelSystemList] = useState([])
  const [TankList, setTankList] = useState([])
  const [inventoryList, setInventoryListset] = useState([])
  const [inventoryValue, setInventoryValue] = useState("")
  const [date, setDate] = useState(null)
  const initialValues = {
    client_id: editData?.client_id,
    site_id: editData?.site_id,
    date: "",
    tank_number: "",
    sales: "",
    deliveries: "",
    actual_ending_inventory_volume: "",
    beginning_inventory_volume: "",
    variance: "",
    book_value: "",
    store_number: "",
    // beginning_inventory_level: "",
    product: "",
    fuel_name: "",
  };
  useEffect(() => {
    setbookvalue(parseFloat(deliveriesValue) + parseFloat(inventoryValue) - parseFloat(salesValues))
    setvarianceValue(parseFloat(actualendingValue) - parseFloat(bookvalue))
  }, [deliveriesValue, inventoryValue, salesValues, varianceValue, actualendingValue, bookvalue])

  const formatDatePickerDate = (date) => {
    if (!date) return null;
    const finaldate = format(date, 'yyyy-MM-dd');
    return finaldate;
  };
  const getProducts = (site) => {
    const payload = {
      site_id: [site],
    };

    ProductService.getProductsDropdown(payload)
      .pipe(errorHandler("Get Product Categories"))
      .subscribe((response) => {
        if (response?.data) {
          setProductList(response?.data || []);
          // getfuelTankList()
        }

      });
  };
  const getfuelSystemList = (product) => {
    const payload = {
      site_id: site,
      product_category: product.product_category_name
    };
    if (product.product_category_name) {
      RolesService.getFuelLineReadingsList(payload)
        .pipe(errorHandler("Get Product Categories"))
        .subscribe((response) => {
          if (response?.data) {
            setFuelSystemList(response?.data || []);
            if (response?.data.length === 1) {
              setfuelsystemValue(response.data[0])
              getfuelTankList(response?.data[0], product)
            }
            setTankList([])
          }
        });
    }
  };
  const getfuelTankList = (Tank, product) => {
    const tankOptions = [];
    RolesService.getFuelTankList(Tank?._id)
      .pipe(errorHandler("Get Tank"))
      .subscribe((response) => {
        if (response?.data && Array.isArray(response?.data)) {
          const modifiedData = response?.data?.flatMap((tank) => {
            if (tank?.tank_a) {
              tankOptions.push({ label: tank?.tank_a, value: tank?.tank_a });
            }
            if (tank?.tank_b) {
              tankOptions.push({ label: tank?.tank_b, value: tank?.tank_b });
            }
            if (tank?.tank_c) {
              tankOptions.push({ label: tank?.tank_c, value: tank?.tank_c });
            }
            return tankOptions;
          });
          const labelsArray = modifiedData.map(item => item.label);
          setTankValue(labelsArray)
          setTankList(modifiedData);
          getfuelInventoryVolumeList(labelsArray, product)
        }
      });
  };
  const getfuelInventoryVolumeList = (inventory_id, product) => {
    let payload
    if(product){
       payload = {
        site_id: site,
        date: formatDatePickerDate(date),
        product: product?.product_category_name,
        tank_number: inventory_id
      }
    } else {
      payload = {
        site_id: site,
        date: formatDatePickerDate(date),
        product: productValue?.product_category_name,
        tank_number: inventory_id
      }
    }
    RolesService.getFuelLineInventoryList(payload)
      .pipe(errorHandler("Get Tank"))
      .subscribe((response) => {
        if (response?.data) {
          setInventoryListset(response?.data || []);
          setInventoryValue(response?.data[0]?.actual_ending_inventory_volume)
        }
      });
  };


  useEffect(() => {
    getProducts(editData?.site_id);
    // getfuelSystemList(editData)
  }, [site]);
  const validationSchema = Yup.object({
    date: Yup.string().required("Date is required"),
    // tank_number: Yup.string().required("Tank Number is required"),
    sales: Yup.string().required("Sales is required"),
    deliveries: Yup.string().required("Deliveries is required"),
    // beginning_inventory_level: Yup.string().required("Beginning Inventory Level is required"),
    actual_ending_inventory_volume: Yup.string().required("Actual Ending Inventory Volume is required"),
    // beginning_inventory_volume: Yup.string().required("Beginning Inventory Volume is required"),
    // variance: Yup.string().required("Variance is required"),
    // book_value: Yup.string().required("Book value is required"),
  });
  const validationByTankNumber = Yup.object({
    date: Yup.string().required("Date is required"),
    // tank_number: Yup.object().required("Tank Number is required"),
  });
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);

    const baseData = {
      ...values,
      product: productValue?.product_category_name,
      fuel_name: fuelsystemValue?.fuel_system_name
    };

    const site = siteName.split('-')[1]; // Assuming you get the site from this
    if (tankValue.length > 1) {
      const payload = tankValue.map((tank) => {
        const inventory = inventoryList.find(item => item.tank_number === tank);
        const beginningInventoryVolume = inventory ? inventory.actual_ending_inventory_volume : 0;

        return {
          ...baseData,
          tank_number: tank,
          sales: 0,
          deliveries: 0,
          actual_ending_inventory_volume: 0,
          beginning_inventory_volume: beginningInventoryVolume,
          variance: 0,
          book_value: 0,
          water_level: 0,
          store_number: site,
          beginning_inventory_level: 0
        };
      });
      onSave(payload);
    } else {
      const inventory = inventoryList.find(item => item.tank_number === tankValue[0]);
      const beginningInventoryVolume = inventory ? inventory.actual_ending_inventory_volume : 0;
      // if (inventoryValue.length > 1) {
      //     const payload = inventoryValue.map(() => {
      //         return {
      //             ...baseData,
      //             tank_number: tankValue[0],
      //             sales: salesValues,
      //             actual_ending_inventory_volume: actualendingValue,
      //             beginning_inventory_volume: beginningInventoryVolume,
      //             variance: varianceValue,
      //             book_value: bookvalue,
      //             water_level: 0,
      //             store_number: site,
      //             beginning_inventory_level: 0
      //         };
      //     });
      //     onSave(payload);
      // } else {
      onSave([{
        ...baseData,
        sales: salesValues,
        actual_ending_inventory_volume: actualendingValue,
        beginning_inventory_volume: beginningInventoryVolume,
        variance: varianceValue,
        book_value: bookvalue,
        tank_number: tankValue[0],
        water_level: 0,
        store_number: site,
        beginning_inventory_level: 0
      }]);
    }
  };


  const calculateBookValue = (beginning_inventory, deliveries, sales) => {
    const beginningInventory = parseFloat(inventoryValue) || 0;
    const delivery = parseFloat(deliveriesValue) || 0;
    const sale = parseFloat(salesValues) || 0;
    return (beginningInventory + delivery) - sale;
  };
  const calculateVariance = (book_value, actual_ending_inventory) => {
    const bookValue = parseFloat(book_value) || 0;
    const actualEndingInventory = parseFloat(actual_ending_inventory) || 0;
    return bookValue - actualEndingInventory;
  };
  const handleFieldChange = (field, value, setFieldValue, values) => {
    setFieldValue(field, value);

    if (
      field === "beginning_inventory_volume" ||
      field === "sales" ||
      field === "deliveries"
    ) {
      const bookValue = calculateBookValue(
        values.beginning_inventory_volume,
        values.deliveries,
        values.sales
      );
      setFieldValue("book_value", bookValue);
      const variance = calculateVariance(bookValue, values?.actual_ending_inventory_volume);
      setFieldValue("variance", variance);
    }
    if (field === "actual_ending_inventory_volume") {
      const variance = calculateVariance(values.book_value, value);
      setFieldValue("variance", variance);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={tankValue.length === 1 || tankValue.length === 0 ? validationSchema : validationByTankNumber}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className="fuel-lines-form container py-4 px-5">
          <Field type="hidden" name="_id" />

          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="Date">Date</label>
            <div className="input-wrapper">
              <Field
                name="date"
                component={Calendar}
                dateFormat="mm/dd/yy"
                placeholder="Select Date"
                onChange={(e) => { setFieldValue("date", formatDatePickerDate(e.value)); setDate(e.value) ;setProductValue("");setfuelsystemValue("");setTankValue("");setInventoryValue("")}}
              />
              <ErrorMessage name="date" component="div" className="form-error" />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="product">Product Name</label>
            <div >
              <Dropdown
                value={productValue}
                style={{ width: "238px" }}
                options={productList}
                optionLabel="product_category_name"
                onChange={(e) => { getfuelSystemList(e?.value); setProductValue(e.value);setInventoryValue("");setTankList([]);setTankValue("") }}
                placeholder="Select Product"
                showSelectAll={true}
                selectAllLabel={"Select Product"}
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="product">Fuel System Name</label>
            <div >
              <Dropdown
                value={fuelsystemValue}
                // loading={!options?.length}
                style={{ width: "238px" }}
                options={fuelSystemList}
                showSelectAll={true}
                onChange={(e) => { setfuelsystemValue(e.value); getfuelTankList(e?.target?.value); }}
                placeholder={"Select Fuel System"}
                optionLabel="fuel_system_name"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="Tank_Number">Tank Number</label>

            <div >
              <MultiSelect
                value={tankValue ?? ""}
                style={{ width: "238px" }}
                options={TankList}
                onChange={(e) => { setTankValue(e.value); getfuelInventoryVolumeList(e?.value) }}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Tanks"
                showSelectAll={true}
                selectAllLabel={"Select all"}
              />
              <ErrorMessage name="tank_number" component="div" className="form-error" />
            </div></div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="beginning_inventory_volume">Beginning Inventory Volume</label>
            <div>
              <Field
                type="text"
                name="beginning_inventory_volume"
                value={inventoryValue}
                // disabled={inventoryValue}
                onChange={(e) => { setInventoryValue(e.target.value); handleFieldChange("beginning_inventory_volume", e.target.value, setFieldValue, values) }
                }
              />
              <ErrorMessage
                name="beginning_inventory_volume"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          {<div className="mb-3 d-flex align-items-center">
            <label htmlFor="Sales">Sales</label>
            <div >
              <Field type="text" name="sales"
                disabled={tankValue.length > 1}
                onChange={(e) => { setSalesValues(e.target.value); handleFieldChange("sales", e.target.value, setFieldValue, values) }
                }
              />
              <ErrorMessage name="sales" component="div" className="form-error" />
            </div>
          </div>}
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="deliveries">Deliveries</label>
            <div>
              <Field
                type="text"
                name="deliveries"
                disabled={tankValue.length > 1}
                onChange={(e) => { setDeliveries(e.target.value); handleFieldChange("deliveries", e.target.value, setFieldValue, values) }
                }
              />
              <ErrorMessage name="deliveries" component="div" className="form-error" />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="actual_ending_inventory_volume">Actual Ending Inventory Volume</label>
            <div>
              <Field
                disabled={tankValue.length > 1}
                type="text"
                name="actual_ending_inventory_volume"
                onChange={(e) => { setactualendingValue(e.target.value); handleFieldChange("actual_ending_inventory_volume", e.target.value, setFieldValue, values) }
                }
              />
              <ErrorMessage
                name="actual_ending_inventory_volume"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="book_value">Book Value</label>
            <div>
              <Field
                type="text"
                name="book_value"
                value={isNaN(bookvalue) || bookvalue === 0 ? "" : bookvalue}
                readOnly
              />
              <ErrorMessage
                name="book_value"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <label htmlFor="variance">Variance</label>
            <div>
              <Field
                type="text"
                name="variance"
                value={isNaN(varianceValue) || varianceValue === 0 ? "" : varianceValue}
                readOnly
              />
              <ErrorMessage
                name="variance"
                component="div"
                className="form-error"
              />
            </div>
          </div>
          <div className="form-buttons">
            <Button type="button" className="p-cancel" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" className="p-save" disabled={isSubmitting}>
              Add Row(s)
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FuelSystemLinesForm;

