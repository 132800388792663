import { BehaviorSubject, Observable } from "rxjs";
import { ApiService } from "./api.service.ts";
import { GETSITESDDL_URL } from "./apiUrls.ts";
export const SiteDDLService = {
  observeSites$: new BehaviorSubject<any>([]),
  observeDateRange$: new BehaviorSubject<any>([]),
  getSitesDDL: (body:any): Observable<any> => {
    return new Observable<any>((observer) => {
      ApiService.call(GETSITESDDL_URL.GET_SITESDDL, "POST", body).subscribe({
        next: (response: any) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  }
}