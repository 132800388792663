const OnShowCalendar = () => {
    return(
    setTimeout(() => {
        const selectedDates = document.querySelectorAll('.p-datepicker-calendar .p-highlight');
        if (selectedDates?.length > 0) {
            selectedDates.forEach((dateSpan, idx) => {
                if (idx === 0 || idx === selectedDates.length - 1) {
                    dateSpan.classList.add('first_last_date')
                }
                else{
                  dateSpan.classList.add('reaming_date')
                }
            })
        }
    },100)
)
}


export default OnShowCalendar;

